<template>
  <div>
    <a-card :title="$t('库存预警')">
      <a-row gutter="16">
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <client-select v-model="searchForm.client" :placeholder="$t('货主')" @change="search"></client-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" :placeholder="$t('产品编号, 名称')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>

        <a-col :span="24" :md="12" :xl="12" style="max-width: 100px; margin-bottom: 12px">
          <a-button-group>
            <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
          </a-button-group>
        </a-col>
      </a-row>
      <a-row>
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          :scroll="{ x: 940 }"
          @change="tableChange"
        >
          <div slot="material_image" slot-scope="value, item">
            <div
              v-if="item.material_images.length > 0"
              @click="preview(item.material_images[0].file)"
              style="cursor: pointer"
            >
              <img :src="item.material_images[0].file" width="40" height="40" />
            </div>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <div style="text-align: center">
        <img :src="previewImage" width="400" height="400" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { inventoryList, inventoryExport } from "@/api/material";
import { warehouseOption } from "@/api/option";
import { exportExcel } from "@/utils/excel";
import columns from "./columns.js";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
  },
  data() {
    return {
      columns,
      searchForm: { search: "", page: 1, ordering: undefined, is_inventory_warning: true },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],

      visible: false,
      targetItem: {},
      form: {},
      warehouseItems: [],

      previewVisible: false,
      previewImage: "",
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initialize() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
        this.searchForm.warehouse = this.currentWarehouse;
      });
    },
    list() {
      this.loading = true;
      inventoryList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    exportExcel() {
      inventoryExport(this.searchForm)
        .then((resp) => {
          exportExcel(resp, this.$t("库存报表"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    preview(file) {
      this.previewImage = file;
      this.previewVisible = true;
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
